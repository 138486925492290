import React from "react";
import styles from "./styles.module.scss";
import {Container, Row, Col} from "react-bootstrap";

const StoryLine = (props) => {
    return (
        <Row
            className="justify-content-center"
            style={{backgroundColor: "#fff", margin: "0", opacity: "0.85"}}
            id="storyline"
        >
            <Col sm={12} md={8} lg={6}>
                <div className={styles.storyline}>
                    <div className={styles.head}>Story Line</div>
                    <p><em>Welcome to </em><b>Scission</b>, <em>a dystopian steampunk adventure!</em></p>
                    <p>
                        <em>
                            The planet lies devastated by endless wars. Peace and order in the world are nothing but a
                            distant memory. Shattered by ruling chaos, advanced scientific knowledge has mostly been
                            forgotten. Meanwhile, opposing factions are competing mercilessly in an arms race to rebuild
                            ancient technology and reclaim control of civilization.
                        </em>
                    </p>
                    <p><em>Gather an army, unlock the ultimate technology of the past and become the greatest strategist
                        of the new Renaissance! </em></p>

                </div>
                <div className={styles.storyline}>
                    <div className={styles.head}>Description</div>
                    <p>
                        <b><em>Scission</em></b> is a 2 to 4 player engine-building battle card game. Players confront
                        each other as they compete for resources and engage in combat to defeat their opponents or
                        protect their allies. The last player standing wins the game!
                    </p>
                    <p><b><em>Scission</em></b> can be
                        played either as a battle royale or a nerve-wracking 2 against 2 duel. Each turn, players will
                        have to decide how much to strengthen their army, advance their technology or attack their
                        adversaries. They accomplish this by drawing and playing cards, and efficiently deploying the
                        scientific discoveries they will accumulate during the game. At the same time, players will have
                        to plan their attacks carefully to stack the odds in their favour and craft multiple card
                        combos! </p>
                    <p><b><em>Scission</em></b> introduces novel card racks which hold cards in an upright
                        position, revealing incomplete information about your cards to other players. This, combined
                        with original upgrading, expanding and conversion mechanics yields a highly entertaining
                        strategic experience.
                    </p>
                </div>
                <div className={styles.storyline}>
                    <div className={styles.head}>Credits</div>
                    <p><b><em>Scission</em></b> is currently under development. The KickStarter campaign is expected
                        during
                        2021.
                    </p>
                    <p><b>Design</b> by Alexandre Larrain & Elena Marimón Muñoz. <b>Artwork</b> by
                        María Pizarro Nuñez.</p>
                    <p><b>Tabletop Simulator</b> version developed by Andreas Gabel (snoggle1981).</p>
                </div>
            </Col>
        </Row>
    )
        ;
};

export default StoryLine;


