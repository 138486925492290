import React, { useState } from "react";
import { Toast } from "react-bootstrap";
function Example(props) {
  return (
    <Toast onClose={props.close} show={props.show} delay={3000} autohide style={{marginTop:"20px"}}>
      <Toast.Header closeButton={false}>
        <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
        <strong className="mr-auto">Subscription</strong>
        <small>{props.code}</small>
      </Toast.Header>
      <Toast.Body style={{ color: "black" }}>{props.message}</Toast.Body>
    </Toast>
  );
}

export default Example;
