import React, {useState} from "react";
import styles from "./styles.module.scss";
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import Toast from "../../Components/Toast/Toast";
import {firebase} from "../../helpers/firebase";
import {md5} from "../../helpers/md5";

const NewsLetter = (props) => {
    const [email, setEmail] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [code, setCode] = useState("");
    const [message, setMessage] = useState("");
    const handleSubmit = (e) => {
        e.preventDefault();

        //Make Request
        console.log("[SUBMITED VALUE]: ", email);

        const db = firebase.firestore();
        const subsRef = db.collection("subscriptions");
        if (email === "") {
            setCode("EMPTY");
            setMessage("Field is required !");
            setShowToast(true);
            return;
        }
        subsRef
            .where("email", "==", email.toString())
            .get()
            .then((snap) => {
                if (snap.docs.length === 1) {
                    setCode("EXIST");
                    setMessage("You have already subscribed!");
                    setShowToast(true);
                } else {
                    subsRef
                        .add({
                            email,
                            hash: md5(email),
                            is_subscribed: true,
                        })
                        .then((res) => {
                            if (res.id) {
                                setCode("SUBSCRIBED");
                                setMessage("You have subscribed successfully !");
                                setShowToast(true);
                            }
                        });
                }
            });

        // subsRef.get().then((snapshot) => {
        //   snapshot.docs.map((item) => {
        //     console.log("[YOUR DATA]: ", item.data());
        //   });
        // });
    };

    const handleInput = (e) => {
        setEmail(e.target.value);
    };

    return (
        <>
            <div className={styles.newsLetter} id="subscribe">
                <Container style={{opacity: "0.75"}}>
                    <Row className="justify-content-center">
                        <Col sm={12} md={12} lg={6}>
                            <h3 style={{marginBottom: "20px"}}>
                                Subscribe to our mailing list
                            </h3>

                            <Form className={styles.subscribeForm} onSubmit={handleSubmit}>
                                <Form.Control
                                    type="email"
                                    placeholder="Your email address"
                                    className={styles.formControl}
                                    onChange={handleInput}
                                    value={email}
                                    name={email}
                                />
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className={styles.formSubmit}
                                >
                                    Subscribe!
                                </Button>
                            </Form>
                            <Toast
                                show={showToast}
                                close={() => setShowToast(!showToast)}
                                code={code}
                                message={message}
                                style={{marginTop: "20px"}}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default NewsLetter;
