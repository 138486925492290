import React, {useState} from "react";
import {Container, Form, Button, Alert} from "react-bootstrap";
import {firebase} from "../helpers/firebase";
import {md5} from "../helpers/md5";
import styles from "./styles.module.scss";

const Blog = (props) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [html, setHTML] = useState("");
    const [tags, setTags] = useState("");
    const [file, setFile] = useState({});
    const [percent, setPercent] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [image, setImage] = useState("");
    const [fileText, setFileText] = useState("Select an image to upload");
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState("");
    const [message, setMessage] = useState("");

    const db = firebase.firestore();
    const blogRef = db.collection("blogs");

    const handleInputs = (e) => {
        switch (e.target.name) {
            case "title":
                setTitle(e.target.value);
                break;
            case "tags":
                setTags(e.target.value);
                break;
            case "description":
                setDescription(e.target.value);
                break;
            case "html":
                setHTML(e.target.value);
                break;
            case "file":
                console.log("FILE: ", e.target.files[0]);
                setFile(e.target.files[0]);
                startUploading(e.target.files[0]);
                break;
        }
    };

    const startUploading = (_file) => {
        if (!_file) return;
        setIsUploading(true);
        const st = firebase.storage().ref(_file.name);
        const task = st.put(_file);
        task.on(
            "state_changed",
            (snapshot) => {
                setPercent((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setFileText(
                    ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2) +
                    "%"
                );
            },
            () => {
                console.log("[ERROR]");
            },
            () => {
                setFileText(`${_file.name} - Uploaded successfully.`);
                st.getDownloadURL()
                    .then((url) => {
                        setImage(url);
                        setIsUploading(false);
                    })
                    .catch((error) => {
                        console.log("[Error while getting url]");
                    });
            }
        );
    };

    const _showAlert = (s, v, m) => {
        setShowAlert(s);
        setVariant(v);
        setMessage(m);
        setTimeout(() => setShowAlert(false), 3000);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            isUploading &&
            title !== "" &&
            tags !== "" &&
            description !== "" &&
            html !== ""
        ) {
            _showAlert(
                true,
                "warning",
                "Waiting for image to upload, please wait..."
            );
            return;
        }

        if (
            title === "" ||
            tags === "" ||
            description === "" ||
            image === "" ||
            html === ""
        ) {
            _showAlert(true, "danger", "All fields are required !");
            return;
        }

        blogRef
            .add({
                id: md5(Date.now().toString()),
                title,
                tags,
                description,
                date: Date.now(),
                image,
                html,
            })
            .then((res) => {
                if (res.id) {
                    _showAlert(true, "success", "Blog published successfully !");
                    setTitle("");
                    setDescription("");
                    setTags("");
                    setImage("");
                    setFileText("Select an image to upload");
                    setHTML("");
                }
            });
    };

    return (
        <Container className={styles.blog}>
            <h2>
                <span>Blog</span>
            </h2>
            {showAlert ? <Alert variant={variant}>{message}</Alert> : <></>}
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formGroupEmail">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="title"
                        value={title}
                        onChange={handleInputs}
                        placeholder="Blog title"
                    />
                </Form.Group>
                <Form.Group controlId="formGroupEmail">
                    <Form.Label>Tags</Form.Label>
                    <Form.Control
                        type="text"
                        name="tags"
                        value={tags}
                        onChange={handleInputs}
                        placeholder="Tags comma separated"
                    />
                    <div className={styles.tags}>
                        {tags.split(",").map((tag, idx) => {
                            if (tag !== "" && tag !== " ")
                                return (
                                    <span key={idx}>{tag}</span>
                                );
                        })}
                    </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="description"
                        value={description}
                        onChange={handleInputs}
                        placeholder="Blog Description"
                        rows="5"
                    />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea2">
                    <Form.Label>HTML</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="html"
                        value={html}
                        onChange={handleInputs}
                        placeholder="Blog HTML"
                        rows="5"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.File
                        id="custom-file-translate-scss"
                        label={fileText}
                        lang="en"
                        custom
                        name="file"
                        onChange={handleInputs}
                        multiple={false}
                        disabled={isUploading}
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Publish
                </Button>
            </Form>
        </Container>
    );
};

export default Blog;
