import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
//Components
import SocialNav from "./SocialNav/SocialNav";
import LinksNav from "./LinksNav/LinksNav";
import LlaimaCarousel from "./LlaimaCarousel/LlaimaCarousel";
import About from "./About/About";
import Blogs from "./Blogs/blogs";
import NewsLetter from "./NewsLetter/NewsLetter";
import Footer from "./Footer/Footer";
import { Container } from "react-bootstrap";

const App = (props) => {
  return (
    <div
      id="scroller"
      style={{
        display: "block",
        backgroundColor: "#f5f5f5",
        border: "1px solid transparent",
        width: "100vw",
        height: "100vh",
        overflow: "scroll",
      }}
    >
      <Container id="socialNav"></Container>
      <SocialNav />
      <LinksNav />
      <Container>
        <LlaimaCarousel />
        <About />
        <Blogs />
        <NewsLetter />
        <Footer />
      </Container>
    </div>
  );
};

export default App;
