import React, {useEffect, useState, createRef} from "react";
import {useParams} from "react-router-dom";
import {firebase} from "../../../helpers/firebase";
import {Container, Row, Col} from "react-bootstrap";
import styles from "./styles.module.scss";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const db = firebase.firestore();
const blogRef = db.collection("blogs");

const BlogDetails = (props) => {
    let {id} = useParams();
    let [data, setData] = useState({});
    useEffect(() => {
        blogRef
            .where("id", "==", id.toString())
            .get()
            .then((res) => {
                let d = res.docs[0].data();

                setData(d);
            });
    }, []);

    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0 ? true : false;
    };
    return (
        <Container className={styles.blogDetails}>
            <a href="/" className={styles.hre}>
                {" "}
                <FontAwesomeIcon icon={faArrowLeft}/> Back
            </a>
            {!isEmpty(data) ? (
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <div
                            style={{backgroundImage: `url(${data.image})`}}
                            className={styles.image}
                        ></div>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <div className={styles.tags}>
                            <div className={styles.tags}>
                                {data.tags.length !== 0 ? (
                                    data.tags.split(",").map((tag, i) => {
                                        return (
                                            <span key={i}>{tag}</span>
                                        );
                                    })
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <div className={styles.time}>
                            {new Date(parseInt(data.date)).toDateString()}
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <div className={styles.title}>{data.title}</div>
                    </Col>
                    <Col sm={12} md={12} lg={12}>
                        <div
                            className={styles.htm}
                            dangerouslySetInnerHTML={{__html: data.html}}
                            style={{fontSize: "13px"}}
                        ></div>
                    </Col>
                </Row>
            ) : (
                <></>
            )}
        </Container>
    );
};

export default BlogDetails;
