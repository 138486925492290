import React, { useEffect, createRef } from "react";
import styles from "./styles.module.scss";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { navWidth } from "../../helpers/resize";
import { clickEvent } from "../../helpers/dom";

const customNav = createRef();
const LinksNav = (props) => {
  useEffect(() => {
    navWidth(customNav);
  }, []);
  window.addEventListener("resize", () => navWidth(customNav));
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="sm"
        bg={"dark"}
        variant="dark"
        fixed="top"
        className={styles.linksNav}
        ref={customNav}
        id="nav"
      >
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <Navbar.Brand href="/" className={styles.lnLogo} id="navBrand">
              <img src="./images/llaima_logo_no_text.png" alt="logo" />
            </Navbar.Brand>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav>
            <Nav.Link 
              href="#games" 
              className={styles.navLink + " _navLink"}
              onClick={(ev) => clickEvent(ev, "#games")}
            >
              Games
            </Nav.Link>
            <Nav.Link 
              href="#about" 
              className={styles.navLink + " _navLink"}
              onClick={(ev) => clickEvent(ev, "#about")}
            >
              About
            </Nav.Link>
            <Nav.Link 
              href="#news" 
              className={styles.navLink + " _navLink"}
              onClick={(ev) => clickEvent(ev, "#news")}
            >
              News
            </Nav.Link>
            <Nav.Link
              href="#subscribe"
              className={styles.navLink + " _navLink"}
              onClick={(ev) => clickEvent(ev, "#subscribe")}
            >
              Subscribe
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default LinksNav;
