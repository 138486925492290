import app from "firebase";

var firebaseConfig = {
    apiKey: "AIzaSyCbJ6_49FnCMsgwjtiQieR0d-c7o1m9WbY",
    authDomain: "llaimagames-70591.firebaseapp.com",
    databaseURL: "https://llaimagames-70591.firebaseio.com",
    projectId: "llaimagames-70591",
    storageBucket: "llaimagames-70591.appspot.com",
    messagingSenderId: "329269999396",
    appId: "1:329269999396:web:8b0677eaee4a6b46df65b1",
    measurementId: "G-LL31WYLTVE"
}; // Initialize Firebase

export const firebase = app.initializeApp(firebaseConfig);
