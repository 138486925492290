import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import styles from "./styles.module.scss";
import {firebase} from "../../helpers/firebase";
import {truncateText} from "../../helpers/truncate";
import {Link} from "react-router-dom";

const db = firebase.firestore();
const blogRef = db.collection("blogs");

const Blogs = (props) => {
    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
        blogRef.get().then((snaps) => {
            let data = snaps.docs.map((item) => ({id: item.id, data: item.data()}));
            data = data.reverse().slice(0, 3);
            setBlogs(data);
        });
    }, []);

    return (
        <Row className={styles.blogs} id="news">
            {blogs.length > 0 ? (
                <>
                    <Col sm={12} md={12} lg={12}>
                        <div className={styles.head}>News</div>
                    </Col>
                    {blogs.map((blog, idx) => {
                        return (
                            <Col sm={12} key={blog.id} md={6} lg={4}>
                                <Link
                                    to={`/blog/${blog.data.id}/details`}
                                    style={{textDecoration: "none", color: "black"}}
                                >
                                    <div className={styles.blog} data-id={blog.data.id}>
                                        <div
                                            className={styles.imageSec}
                                            style={{
                                                backgroundImage: `url(${blog.data.image})`,
                                            }}
                                        ></div>
                                        <div className={styles.content}>
                                            <div className={styles.date}>
                                                {new Date(blog.data.date).toDateString()}
                                            </div>
                                            <div className={styles.title}>{blog.data.title}</div>
                                            <div className={styles.tags}>
                                                {blog.data.tags.length !== 0 ? (
                                                    blog.data.tags.split(",").map((tag, i) => {
                                                        return (
                                                            <span key={i}>{tag}</span>
                                                        );
                                                    })
                                                ) : (
                                                    <></>
                                                )}
                                            </div>

                                            <div className={styles.desc}>
                                                {truncateText(blog.data.description, 200)}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        );
                    })}
                </>
            ) : (
                <></>
            )}
        </Row>
    );
};

export default Blogs;
