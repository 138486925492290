import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { firebase } from "../helpers/firebase";
import styles from "./styles.module.scss";

const Emails = (props) => {
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    const db = firebase.firestore().collection("subscriptions");

    db.get().then((snaps) => {
      let data = snaps.docs.map((item) => item.data());
      console.log(data);
      setEmails(data);
    });
  }, []);

  return (
    <Container className={styles.emails}>
      <h2>
        <span>WOw Mailing List</span>
      </h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Email</th>
            <th>Hash</th>
            <th>Subscribed</th>
          </tr>
        </thead>
        <tbody>
          {emails.length > 0 ? (
            emails.map((email, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{email.email}</td>
                  <td>{email.hash}</td>
                  <td>{email.is_subscribed ? "Yes" : "No"}</td>
                </tr>
              );
            })
          ) : (
            <></>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default Emails;
