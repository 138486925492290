import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import App from "./App/app";
import Scission from "./Scission/Scission";
import Blog from "./Blog/blog";
import Emails from "./Emails/emails";
import BlogDetails from "./App/Blogs/BlogDetails/details";
import {BrowserRouter, Route} from "react-router-dom";

ReactDOM.render(
    <BrowserRouter>
        <Route exact path="/" component={App}/>
        <Route path="/games/scission" component={Scission}/>
        <Route path="/xL3-5z49-fHFxwVa/add-blog" component={Blog}/>
        <Route path="/blog/:id/details" component={BlogDetails}/>
        <Route path="/SxH-s-b5CVM4grf/emails" component={Emails}/>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
